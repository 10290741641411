import React, { useEffect, useState } from 'react';
import Loading from '../../components/common/Loading';
import axios from 'axios';
import {
    Box,
    Button,
    Divider,
    IconButton,
    Link,
    Typography,
} from '@mui/material';
import CollapseContainer from '../../components/common/CollapseContainer';
import PopupForm from '../../components/common/PopupForm';
import { getConnectors, getEventTypes } from '../../scripts/cms';
import { toast } from 'react-toastify';
import DynamicIcon from '../../components/common/DynamicIcon';
import { useParams } from 'react-router-dom';
import Amendments from '../../components/cms/Amendments';
import { DataGrid } from '@mui/x-data-grid';
import { getDate, getRow } from '../../scripts/table';
import moment from 'moment';
import { useHotglue } from '@hotglue/widget';

function Project() {
    const [project, setProject] = useState();
    const [showTriggerForm, setShowTriggerForm] = useState(false);
    const [hotglueConfig, setHotglueConfig] = useState();

    const { openWidget } = useHotglue();
    const { slug } = useParams();

    useEffect(() => {
        if (slug) {
            refresh();
        }
    }, [slug]);

    function refresh() {
        axios
            .get(`/administration/getProject/${slug}`)
            .then((res) => {
                setProject(res.data);

                axios
                    .get(`/hotglue/adminWidget/${res.data.developerId}`)
                    .then((res) => {
                        setHotglueConfig(res.data);
                    })
                    .catch(function () {});
            })
            .catch(function () {});
    }

    if (!project) {
        return <Loading />;
    }

    function handleSetupTriggers(form) {
        axios
            .post(`/hotglue/setupTriggers`, form)
            .then((res) => {
                setShowTriggerForm(false);
            })
            .catch(function (err) {
                toast.error(
                    err?.response?.data?.message ||
                        err?.message ||
                        'Request failed'
                );
            });
    }

    return (
        <div className="admin-content">
            <Box padding={3} flex={1} backgroundColor="white">
                <Box display="flex" marginBottom={1}>
                    <Button
                        variant="dark"
                        onClick={() =>
                            (window.location.href = '/administration')
                        }
                    >
                        <DynamicIcon icon="KeyboardBackspace" />
                    </Button>
                    <Typography
                        textAlign="center"
                        flex={1}
                        fontWeight="bold"
                        fontSize={22}
                        marginTop={1.5}
                    >
                        {project.title}
                    </Typography>
                    <Button
                        variant="danger"
                        sx={{ marginRight: 1 }}
                        onClick={() => {
                            openWidget(hotglueConfig?.tenantId, {
                                jwtToken: hotglueConfig?.token,
                                schemas: hotglueConfig?.schemas,
                                nextStep: 'mapping',
                            });
                        }}
                        disabled={!hotglueConfig}
                    >
                        Hotglue
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => setShowTriggerForm(true)}
                        sx={{ marginRight: 1 }}
                    >
                        Setup Triggers
                    </Button>
                    <Button
                        variant="blue"
                        onClick={() => {
                            window.location.href = `/administration/projects/create/${project.slug}`;
                        }}
                    >
                        Edit
                    </Button>
                </Box>
                <Inventory project={project} refresh={refresh} />
                <Listings project={project} refresh={refresh} />
                <PopupForm
                    title="Setup Triggers"
                    body={
                        <>
                            <p>
                                Setup hotglue triggers to notify us when changes
                                are made in a connector.
                            </p>
                            <b>
                                CAUTION: This process will replace any existing
                                triggers.
                            </b>
                        </>
                    }
                    action={handleSetupTriggers}
                    show={showTriggerForm}
                    setShow={setShowTriggerForm}
                    width={800}
                    yes="Submit"
                    inputs={[
                        [
                            {
                                id: 'connectorId',
                                label: 'Connector',
                                source: getConnectors,
                            },
                            { id: 'flowId' },
                        ],
                        [
                            {
                                id: 'fields',
                                title: 'Field',
                                inputs: [[{ id: 'table' }, { id: 'column' }]],
                            },
                        ],
                    ]}
                    defaults={{ developerId: project.developerId }}
                />
            </Box>
        </div>
    );
}

function getRowClassName(params) {
    // Doesn't consume inventory
    if (params.row.quantity === 0) {
        return 'row-disabled';
    }
    // Out of inventory
    else if (params.row.remaining <= 0) {
        return 'row-danger';
    }
}

function Inventory({ project, refresh }) {
    const columns = [
        {
            field: 'upgradeId',
            headerName: 'ID',
            width: 75,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.row.upgrade.id;
            },
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 75,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'type',
            headerName: 'Type',
            headerAlign: 'center',
            width: 200,
            align: 'center',
            renderCell: (params) => {
                return params.row.upgrade.upgradeType.title;
            },
        },
        {
            field: 'subtype',
            headerName: 'Subtype',
            headerAlign: 'center',
            width: 200,
            align: 'center',
            renderCell: (params) => {
                return params.row.upgradeSubtype?.title;
            },
        },
        {
            field: 'upgrade',
            headerName: 'Upgrade',
            headerAlign: 'center',
            flex: 1,
            align: 'center',
            renderCell: (params) => {
                return params.row.upgrade.title;
            },
        },
        {
            field: 'title',
            headerName: 'Option',
            headerAlign: 'center',
            flex: 1,
            align: 'center',
        },
        {
            field: 'usage',
            headerName: 'Usage',
            headerAlign: 'center',
            width: 90,
            align: 'center',
        },
        {
            field: 'remaining',
            headerName: 'Remaining',
            headerAlign: 'center',
            width: 120,
            align: 'center',
        },
        {
            field: 'limit',
            headerName: 'Quantity',
            headerAlign: 'center',
            width: 100,
            align: 'center',
            renderCell: (params) => {
                return params.row.inventory?.quantity;
            },
        },
    ];

    return (
        <Box marginBottom={4}>
            <Typography fontSize={18} fontWeight="medium">
                Inventory
            </Typography>
            <DataGrid
                rows={project.inventory}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'remaining', sort: 'asc' }],
                    },
                }}
                autoHeight
                sx={{ border: 'none' }}
                getRowClassName={getRowClassName}
            />
        </Box>
    );
}

function Listings({ project, refresh }) {
    return (
        <Box marginBottom={4}>
            <EventList
                list={project.projectEvents}
                type="Project"
                eventTypeBody={{ project: true }}
                config={{ projectId: project.id }}
                refresh={refresh}
            />
            {project.addresses?.map((address) => (
                <Box key={address.id}>
                    <Typography fontSize={18} fontWeight="medium">
                        {address.title}
                        {`, ${address.streetAddress}, ${address.city}, ${address.province}`}
                    </Typography>
                    <Box display="flex">
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ marginRight: 1 }}
                        />
                        <Box flex={1}>
                            <EventList
                                list={address.addressEvents}
                                type="Address"
                                eventTypeBody={{ address: true }}
                                config={{ addressId: address.id }}
                                refresh={refresh}
                            />
                            <Typography fontSize={18} fontWeight="medium">
                                Units
                            </Typography>
                            {address.properties.map((property) => (
                                <Box key={property.id} marginTop={1}>
                                    <CollapseContainer
                                        title={`${
                                            property.unitNumber
                                                ? `${property.unitNumber} - `
                                                : ''
                                        }${property.auction.title}`}
                                        expandDefault={true}
                                    >
                                        <Box padding={1}>
                                            <Typography
                                                fontSize={20}
                                                fontWeight="bold"
                                                component={Link}
                                                href={`/listing/${property.auction.slug}`}
                                            >
                                                {property.auction.title}
                                            </Typography>
                                            <EventList
                                                list={property.propertyEvents}
                                                type="Property"
                                                eventTypeBody={{
                                                    property: true,
                                                }}
                                                config={{
                                                    propertyId: property.id,
                                                }}
                                                refresh={refresh}
                                            />
                                            <Amendments
                                                purchase={
                                                    property.auction.purchase
                                                }
                                                auction={property.auction}
                                                project={project}
                                                refresh={refresh}
                                            />
                                        </Box>
                                    </CollapseContainer>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    );
}

export function EventList({
    list = [],
    type,
    eventTypeBody,
    config,
    refresh = () => {},
    simple,
}) {
    const defaultForm = {
        expectedDate: null,
        scheduleDate: null,
        completedAt: null,
        completed: false,
    };

    const [showForm, setShowForm] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [current, setCurrent] = useState(defaultForm);

    let editing = current?.id > 0;

    function handleSubmit(form) {
        axios
            .post(`/event/createOrUpdate`, { ...form, ...config })
            .then((res) => {
                setShowForm(false);
                refresh();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    function handleDelete(form) {
        axios
            .post(`/event/delete`, { ...form, ...config })
            .then((res) => {
                setShowDelete(false);
                refresh();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'eventTypeId',
            headerName: 'Type',
            width: 250,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.row.eventType;
            },
        },
        {
            field: 'expectedDate',
            headerName: 'Expected Date',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'scheduleDate',
            headerName: 'Schedule Date',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'completedAt',
            headerName: 'Completed At',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'cancellationDate',
            headerName: 'Cancellation Date',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
    ];

    // Add additional columns if needed
    if (!simple) {
        columns.push(
            ...[
                {
                    field: 'completed',
                    headerName: 'Done',
                    width: 70,
                    headerAlign: 'center',
                    align: 'center',
                    renderCell: (params) => {
                        return params.row.completed ? (
                            <DynamicIcon icon="Check" colour="green" />
                        ) : (
                            ''
                        );
                    },
                },
                {
                    field: 'createdAt',
                    headerName: 'Created At',
                    width: 170,
                    headerAlign: 'center',
                    align: 'center',
                },
                {
                    field: 'edit',
                    headerName: 'Edit',
                    width: 90,
                    headerAlign: 'center',
                    align: 'center',
                    renderCell: (params) => {
                        const locked =
                            params.row.completed || params.row.locked;
                        const onClick = (e) => {
                            setCurrent(getRow(e, params));
                            setShowForm(true);
                        };

                        return (
                            <IconButton onClick={onClick} disabled={locked}>
                                <DynamicIcon
                                    icon="Edit"
                                    colour={locked ? '#999' : '#166cdb'}
                                />
                            </IconButton>
                        );
                    },
                    sortable: false,
                },
            ]
        );
    }

    return (
        <Box marginBottom={1} width="100%">
            <Typography fontSize={18} fontWeight="medium" gutterBottom>
                {type} Events
            </Typography>
            {list.length > 0 && (
                <DataGrid
                    rows={list.map((item) => {
                        const event = item.event;

                        return {
                            id: event.id,
                            eventTypeId: event.eventTypeId,
                            eventType: event.eventType.title,
                            expectedDate: getDate(event.expectedDate),
                            scheduleDate: getDate(event.scheduleDate),
                            completedAt: getDate(event.completedAt),
                            completed: event.completed,
                            cancellationDate: getDate(event.cancellationDate),
                            createdAt: getDate(event.createdAt, true),
                        };
                    })}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    autoHeight
                    sx={{ backgroundColor: 'white', marginBottom: 1 }}
                />
            )}

            {!simple && (
                <Button
                    variant="dark"
                    size="tiny"
                    onClick={() => {
                        setShowForm(true);
                        setCurrent(defaultForm);
                    }}
                >
                    Add
                </Button>
            )}
            <PopupForm
                title={`${editing ? 'Modify' : 'Create'} Event`}
                yes="Submit"
                show={showForm}
                setShow={setShowForm}
                action={handleSubmit}
                inputs={[
                    [
                        {
                            id: 'eventTypeId',
                            source: getEventTypes,
                            sourceBody: eventTypeBody,
                            disabled: editing,
                        },
                    ],
                    [
                        {
                            id: 'expectedDate',
                            date: true,
                            optional: true,
                            disabled: current.completed || current.locked,
                        },
                    ],
                    [
                        {
                            id: 'scheduleDate',
                            date: true,
                            optional: true,
                            disabled: current.completed || current.locked,
                        },
                    ],
                    [
                        {
                            id: 'completedAt',
                            date: true,
                            optional: true,
                            disabled: true,
                        },
                    ],
                ]}
                processing={current.completed || current.locked}
                defaults={current}
            />
            <PopupForm
                title="Delete?"
                yes="Submit"
                show={showDelete}
                setShow={setShowDelete}
                action={handleDelete}
                body="Are you sure you want to delete this event"
                defaults={current}
            />
        </Box>
    );
}

export default Project;
