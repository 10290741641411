import { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import DynamicIcon from '../../components/common/DynamicIcon';
import moment from 'moment';
import { toast } from 'react-toastify';

function Envelope({ title, data, admin, user, broker }) {
    const [envelope, setEnvelope] = useState(data);
    const [expand, setExpand] = useState(data?.status === 'sent');

    const [loading, setLoading] = useState(false);

    if (!envelope) {
        return null;
    }

    function refreshRecipients() {
        setLoading(true);

        axios
            .post(`/docusign/refreshRecipients/${envelope.id}`)
            .then((res) => {
                setEnvelope(res.data);
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            })
            .finally(function () {
                setLoading(false);
            });
    }

    return (
        <Box
            marginBottom={1}
            padding={1.5}
            backgroundColor="tint"
            borderRadius={2}
            marginRight={1}
        >
            <Box display="flex">
                <Box flex={1} display="flex">
                    <DocusignStatus
                        status={envelope.status}
                        tooltip={envelope.id}
                        admin={admin}
                    />
                    <Box
                        display="flex"
                        marginTop="auto"
                        marginBottom="auto"
                        onClick={() => setExpand(!expand)}
                        className="clickable"
                    >
                        <Typography fontWeight="bold">{title}</Typography>
                    </Box>
                </Box>
                <IconButton
                    sx={{
                        color: 'dark',
                        width: 20,
                        height: 20,
                        // border: '1px solid',
                        // borderColor: 'dark',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        marginLeft: 1,
                    }}
                    onClick={() => setExpand(!expand)}
                >
                    <DynamicIcon
                        icon={expand ? 'ExpandLess' : 'ExpandMore'}
                        size={16}
                    />
                </IconButton>
            </Box>
            <Collapse in={expand}>
                <Box
                    borderLeft="1px solid #ccc"
                    paddingLeft={1.3}
                    marginTop={1}
                >
                    {envelope?.recipients?.map((recipient) => (
                        <Box display="flex" key={recipient.id}>
                            <DocusignStatus
                                status={recipient.status}
                                number={recipient.routingOrder}
                                tooltip={
                                    recipient.signDate
                                        ? moment(recipient.signDate).format(
                                              'MM-DD-YYYY h:mm:ss A'
                                          )
                                        : recipient.status
                                }
                                admin={admin}
                            />
                            <Typography>{recipient.name}</Typography>
                        </Box>
                    ))}
                </Box>
                {admin && (
                    <Button
                        onClick={refreshRecipients}
                        size="tiny"
                        sx={{ padding: 0, marginTop: 1 }}
                        variant="white"
                        disabled={loading}
                    >
                        {loading ? '...' : 'Refresh'}
                    </Button>
                )}
            </Collapse>
        </Box>
    );
}

function DocusignStatus({ status, number, tooltip, admin }) {
    let colour = 'status.disabled';
    let icon = 'QuestionMark';

    tooltip = tooltip || status;

    switch (status) {
        // Completed
        case 'completed':
            colour = 'status.success';
            icon = 'CheckCircle';
            break;

        // Delivered (Copy of Sent)
        case 'delivered':
            colour = 'status.info';
            icon = number ? (admin ? `Filter${number}` : 'Edit') : 'Assignment';
            break;

        // Sent
        case 'sent':
            colour = 'status.info';
            icon = number ? (admin ? `Filter${number}` : 'Edit') : 'Assignment';
            break;

        // Created (Blocked by others)
        case 'created':
            colour = 'status.disabled';
            icon = number ? (admin ? `Filter${number}` : 'Edit') : 'Pending';
            break;

        // Voided
        case 'voided':
            colour = 'status.danger';
            icon = 'Block';
            break;

        // Default
        default:
            break;
    }

    return (
        <Tooltip
            title={
                <Box display="flex">
                    <DynamicIcon
                        icon="CopyAll"
                        size={15}
                        sx={{
                            padding: 0,
                            marginTop: 'auto',
                            marginBottom: 'auto',
                        }}
                    />
                    <Typography
                        fontSize={13}
                        marginLeft={0.5}
                        marginTop="auto"
                        marginBottom="auto"
                    >
                        {tooltip}
                    </Typography>
                </Box>
            }
            placement="top"
        >
            <IconButton
                sx={{ marginRight: 1, padding: 0 }}
                onClick={() => {
                    navigator.clipboard.writeText(tooltip);
                    toast.success('Copied text to clipboard');
                }}
                disabled={!admin}
            >
                <DynamicIcon icon={icon} colour={colour} />
            </IconButton>
        </Tooltip>
    );
}

export default Envelope;
